<template>
  <v-app-bar
    app
    flat
    :color="color"
    class="px-md-8 px-1"
    >
    <v-toolbar-title
      class="font-weight-bold text-h4"
      :class="color == 'transparent' ? 'white--text' : 'primary--text'"
      >
      SHIBUI
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      :class="color == 'transparent' ? 'white--text' : 'black--text'"
      :color="color == 'transparent' ? 'white' : 'primary'"
      depressed
      @click="goToWhatsapp"
      >
      Contáctanos
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    color: 'transparent'
  }),

  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },

  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
      ) {
        this.color = 'rgba(22, 22, 22, 0.8)';
      } else {
        this.color = 'transparent';
      }
    },

    goToWhatsapp () {
      const base = "https://wa.me/5493482302735?text="
      const text = `¡Buenos días! Estoy necesitando `
      const uri = base + text

      window.open(uri)
    },
  },
}
</script>
