<template>
  <v-app id="inspire">
    <app-bar></app-bar>

    <v-main
      >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/bars/AppBar.vue'

export default {
  name: "App",

  components: {
    AppBar
  }
};
</script>
