// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#d2f0ee",
        secondary: "#fdc5c6",
        accent: "#8c9eff",
        error: "#b71c1c",
        oter: "#f2e8f4",
      },
      dark: {
        primary: "B6E388",
        secondary: "#CBB78B",
      }
    },
  },
});
